<template>
  <div>
    <h3 style="font-weight:500;text-align:center;margin-top:0;">{{ title }}</h3>
    <el-row class="mb-3" style="font-size:14px;">
      <el-col v-for="(item,index) in row.labelArr" :key="index" :span="6" class="mb-1">{{ item.label }}：{{ item.value }}
      </el-col>
      <!-- 渠道 -->
      <el-col v-if="row.type=='offer'" :span="6">
        <span style="color:red;font-weight:700;">*</span>
        渠道: <el-select v-model="row.shipInfo.logisticsChannel" value-key="id" clearable filterable style="width:150px;">
          <el-option
            v-for="item in _getAllCommodityDict('LOGISTICS_CHANNEL_TYPE')"
            :key="item.val"
            :label="item.label"
            :value="item.val"
          />
        </el-select>
      </el-col>
      <!-- 附件 -->
      <el-col v-if="row.type=='carriers'||row.type=='offer'" :span="6">附件：
        <!-- <input ref="fileId" type="file" style="display:inline-block;width:180px;" limit="1" @change="getFile"><i v-if="file" class="el-icon-circle-close" @click="clearFile" /> -->
        <input ref="fileId" type="file" style="display: none" limit="1" @change="getFile">
        <el-button type="text" @click="handleUploadBtnClick">点击上传</el-button>
        <span
          v-if="file.name|| row.shipInfo.filePath"
          class="ml-2"
        >{{ file.name|| row.shipInfo.filePath.split('/')[row.shipInfo.filePath.split('/').length-1] }}
          <i v-if="file|| row.shipInfo.filePath" class="el-icon-circle-close ml-2" @click="clearFile" /></span>
      </el-col>

      <!-- 查看 -->
      <el-col v-if="row.type=='view'&&row.shipInfo.feeSnippet!=='关税'" :span="6">渠道: <el-select
        v-model="row.shipInfo.logisticsChannel"
        value-key="id"
        clearable
        filterable
        style="width:150px;"
        disabled
      >
        <el-option
          v-for="item in _getAllCommodityDict('LOGISTICS_CHANNEL_TYPE')"
          :key="item.val"
          :label="item.label"
          :value="item.val"
        />
      </el-select>
      </el-col>
      <el-col v-if="row.type=='view'&&row.shipInfo.feeSnippet!=='关税'" :span="6">附件：
        <el-button v-if="row.shipInfo.filePath" type="text" @click="downloadByThoms(row.shipInfo.filePath)">
          {{ row.shipInfo.filePath.split('/')[row.shipInfo.filePath.split('/').length-1] }}</el-button>
      </el-col>

    </el-row>
    <el-form
      v-if="(row.type==='carriers'&&row.shipInfo.feeSnippet==='关税')"
      ref="form"
      :model="row.shipInfo"
      label-width="70px"
      label-position="left"
    >
      <el-row type="flex" class="invoice">
        <el-col :span="6">
          <el-form-item label="发票号：">
            <el-input v-model="row.shipInfo.invoiceNo" :disabled="row.type==='view'" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发运港：">
            <el-input v-model="row.shipInfo.shipHarbor" :disabled="row.type==='view'" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="进口港：">
            <el-input v-model="row.shipInfo.importHarbor" :disabled="row.type==='view'" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 关税的查看详情去清关登记页面 -->
    <div v-if="(row.type==='view'&&row.shipInfo.feeSnippet!=='关税')||(row.type!=='info')">
      <el-form ref="form" label-width="60px" :inline="true" label-position="left" :rules="rules" :model="row">
        <div v-for="(item,index) in row.list" :key="index">
          <div class="logistic mb-3">
            <div style="width:100%;">
              <el-row class="mt-1" type="flex" justify="start" style="padding:12px;">
                <el-col class="mr-2" :span="row.type==='offer'?24:10"> {{ item.feeSnippet }}（{{ item.totalPrice }}）
                </el-col>
                <el-col v-if="row.type==='carriers'&&row.shipInfo.feeSnippet!=='关税'">
                  <el-checkbox v-model="item.onePriceTag" disabled :true-label="1" :false-label="0">是否一口价</el-checkbox>
                </el-col>
              </el-row>
              <el-row type="flex" justify="end" style="height:45px;" class="first">
                <el-col :span="5.5">
                  <el-form-item
                    :label="row.type === 'carriers' && row.shipInfo.feeSnippet === '关税'?'进口方：':'结算主体：'"
                    label-width="90px"
                    :prop="'list.'+index+'.settleEntityCode'"
                    :rules="rules.settleEntityCode"
                  >
                    <el-select
                      v-model="item.settleEntityCode"
                      value-key="id"
                      placeholder="请选择"
                      filterable
                      :disabled="row.type==='view'"
                    >
                      <el-option
                        v-for="e in cargoOwnerNameList"
                        :key="e.cargoOwnerName"
                        :label="e.cargoOwnerName"
                        :value="e.cargoOwnerCode"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5.5">
                  <!-- 项目 -->
                  <el-form-item
                    :label="row.type === 'carriers' && row.shipInfo.feeSnippet === '关税'?'清关行：':'承运商：'"
                    label-width="75px"
                    :prop="'list.'+index+'.carrierCode'"
                    :rules="rules.carrierCode"
                  >
                    <el-select
                      v-model="item.carrierCode"
                      value-key="id"
                      placeholder="请选择"
                      filterable
                      :disabled="row.type==='view'"
                      @change="getCostTypeList($event,item)"
                    >
                      <el-option v-for="e in item.carrierList" :key="e.code" :label="e.label" :value="e.code" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 项目&承运商 -->
                <el-col :span="5.5">
                  <el-form-item
                    label="费用类别："
                    label-width="90px"
                    :prop="'list.'+index+'.feeTypeCode'"
                    :rules="rules.feeTypeCode"
                  >
                    <el-select
                      v-model="item.feeTypeCode"
                      :disabled="row.type==='view'"
                      value-key="id"
                      placeholder="请选择"
                      filterable
                      @change="getFeeitemList($event,item)"
                      @visible-change="visibleChange($event,item)"
                    >
                      <el-option v-for="e in item.costTypeList" :key="e.code" :label="e.label" :value="e.code" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5.5">
                  <el-form-item label="币种：" :prop="'list.'+index+'.currencyCode'" :rules="rules.currencyCode">
                    <el-select
                      v-model="item.currencyCode"
                      :disabled="row.type==='view'"
                      value-key="id"
                      filterable
                      style="width:100%;"
                      @change="getResultPrice(1)"
                    >
                      <el-option
                        v-for="e in _getAllCommodityDict('CURRENCY_TYPE')"
                        :key="e.val"
                        :label="e.label"
                        :value="e.val"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-if="!item.onePriceTag">
                <el-row class=" first" style="padding:12px;border-top:1px solid #000;">
                  <el-col v-for="(e,i) in item.regItemDetailsList" :key="i" :span="6" style="max-height:50px;">
                    <el-form-item
                      v-if="row.type!=='view'||(row.type==='view'&&e.feeItemFees)"
                      :label="row.type === 'carriers' && row.shipInfo.feeSnippet === '关税'?'税费合计：':e.feeItem+'：'"
                      label-width="90px"
                    >
                      <el-input
                        v-model="e.feeItemFees"
                        :disabled="row.type==='view'"
                        type="number"
                        @blur="e.feeItemFees=blurFee($event,item)"
                        @wheel.native.prevent="stopScroll($event)"
                      />
                    </el-form-item>
                  </el-col>
                  <div v-if="row.type==='view'&&item.regItemSpecialDetailsList.length" style="padding:0  12px 12px 0;">
                    <div style="width:100px;">特殊费用：</div>
                    <Table
                      :max-height="'300px'"
                      class="mt-1"
                      :columns="specialFeeColumns"
                      :table-data="item.regItemSpecialDetailsList"
                      :width="750"
                    >
                      <el-table-column slot="view" label="操作">
                        <template slot-scope="scope">
                          <el-button v-if="scope.row.filePath" type="text" @click="downloadByThoms(scope.row.filePath)">
                            下载</el-button>
                        </template>
                      </el-table-column>
                    </Table>
                  </div>
                </el-row>
              </div>
              <el-row v-else>
                <el-row class="first" style="padding:12px;border-top:1px solid #000;">
                  <el-col v-for="(e,i) in item.regItemDetailsList" :key="i" :span="6" style="height:49px;">
                    <el-form-item
                      :label="row.type === 'carriers' && row.shipInfo.feeSnippet === '关税'?'税费合计：':e.feeItem+'：'"
                      label-width="90px"
                    >
                      <el-input
                        v-model="e.feeItemFees"
                        :disabled="row.type==='view'"
                        type="number"
                        @blur="e.feeItemFees=blurFee($event,item)"
                        @wheel.native.prevent="stopScroll($event)"
                      />
                    </el-form-item>

                  </el-col>
                  <div v-if="row.type==='view'&&item.regItemSpecialDetailsList.length" style="padding:0  12px 12px 0;">
                    <div style="width:100px;">特殊费用：</div>
                    <Table
                      :max-height="'300px'"
                      class="mt-1"
                      :columns="specialFeeColumns"
                      :table-data="item.regItemSpecialDetailsList"
                      :width="750"
                    >
                      <el-table-column slot="view" label="操作">
                        <template slot-scope="scope">
                          <el-button v-if="scope.row.filePath" type="text" @click="downloadByThoms(scope.row.filePath)">
                            下载</el-button>
                        </template>
                      </el-table-column>
                    </Table>
                  </div>
                </el-row>
              </el-row>

            </div>
          </div>
        </div>
      </el-form>
    </div>
    <el-row v-if="row.type==='offer'||(row.type==='view'&&row.shipInfo.feeSnippet!=='关税')" type="flex" justify="end">
      费用合计：<span class="mr-3">【CNY】{{ cny }}</span><span class="mr-4">【USD】{{ usd }}</span>
    </el-row>

    <!-- 清关信息登记 -->
    <div v-if="row.type==='info'||(row.type==='view'&&row.shipInfo.feeSnippet==='关税')" class="three">
      <el-form ref="form" :model="row.shipInfo" label-width="100px" :rules="infoRules">
        <el-row type="flex" class="mt-3">
          <el-col :span="5">
            <el-form-item label="进口方：" prop="settleEntityCode">
              <el-select
                v-model="row.shipInfo.settleEntityCode"
                value-key="id"
                placeholder="请选择"
                filterable
                clearable
                style="width:100%;"
                :disabled="row.type==='view'"
              >
                <el-option
                  v-for="item in cargoOwnerNameList"
                  :key="item.cargoOwnerName"
                  :label="item.cargoOwnerName"
                  :value="item.cargoOwnerCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="清关行：" prop="carrierCode">
              <el-select
                v-model="row.shipInfo.carrierCode"
                :disabled="row.type==='view'"
                value-key="id"
                placeholder="请选择"
                filterable
                clearable
                style="width:100%;"
              >
                <el-option
                  v-for="item in newCarrierList"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.vendorCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="币种：" prop="currencyCode" style="width:100%;">
              <el-select
                v-model="row.shipInfo.currencyCode"
                :disabled="row.type==='view'"
                value-key="id"
                clearable
                filterable
                style="width:100%;"
              >
                <el-option
                  v-for="item in _getAllCommodityDict('CURRENCY_TYPE')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="发票号：">
              <el-input v-model="row.shipInfo.invoiceNo" :disabled="row.type==='view'" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding:10px 10px 0 0;">
          <el-col :span="5">
            <el-form-item label="发运港：">
              <el-input v-model="row.shipInfo.shipHarbor" :disabled="row.type==='view'" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="进口港：">
              <el-input v-model="row.shipInfo.importHarbor" :disabled="row.type==='view'" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="税费合计：" prop="feeItemFees">
              <!-- 大于0 -->
              <el-input-number
                v-model="row.shipInfo.feeItemFees"
                controls-position="right"
                :precision="2"
                style="width:100%;"
                :step="1"
                :disabled="row.type==='view'"
                @change="handleChange($event,'feeItemFees')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item v-if="row.type!=='view'" label="清关材料：">
              <input ref="fileId" type="file" style="display: none" limit="1" @change="getFile">
              <el-button type="text" @click="handleUploadBtnClick">点击上传</el-button><span
                v-if="file.name|| row.shipInfo.filePath"
                class="ml-2"
              >{{ file.name|| row.shipInfo.filePath.split('/')[row.shipInfo.filePath.split('/').length-1] }}<i
                v-if="file|| row.shipInfo.filePath"
                class="el-icon-circle-close ml-2"
                @click="clearFile"
              /></span>
            </el-form-item>
            <el-form-item v-else label="清关材料：">
              <el-button v-if="row.shipInfo.filePath" type="text" @click="downloadByThoms(row.shipInfo.filePath)">
                {{ row.shipInfo.filePath.split('/')[row.shipInfo.filePath.split('/').length-1] }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <el-row style="text-align:center;" class="mt-3">
      <el-button type="primary" @click="cancel">{{ cancelTxt }}</el-button>
      <el-button v-if="row.type!=='view'" type="primary" :loading="submitLoading" @click="submit">{{ operate }}
      </el-button>
    </el-row>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Table from '@/components/Table'
import { prepareColumns, specialFeeColumns, FeeList } from '@/utils/table-columns.js'
import { getcargoownerList, getVendorListByVendorType } from '@/api/select.js'
import { validAmount } from '@/utils/validate'
import { FloatAdd } from '@/utils/acc'
import { feeItemOnSelect, reg, carrierChangeForRegItem, clearanceReg, downloadByThoms } from '@/api/carriers'
import store from '@/store'
import { deepClone } from '@/utils'
import defaultSettings from '@/settings'

export default {
  components: { Table },
  mixins: [commodityInfoDict],
  data() {
    return {
      newCarrierList: [],
      carrierCode: '',
      submitLoading: false,
      FeeList,
      cny: 0,
      usd: 0,
      fileList: [],
      specialFeeColumns,
      cargoOwnerNameList: [],
      prepareColumns,
      file: '',
      Loading: false,
      row: {
        list: [],
        labelArr: [],
        shipInfo: { invoiceNo: '', importHarbor: '', feeItemFees: '', shipHarbor: '' }
      },
      rules: {
        settleEntityCode: [{ required: true, message: '必填', trigger: 'change' }],
        carrierCode: [{ required: true, message: '必填', trigger: 'change' }],
        feeTypeCode: [{ required: true, message: '必填', trigger: 'change' }],
        currencyCode: [{ required: true, message: '必填', trigger: 'change' }]
      },
      infoRules: {
        settleEntityCode: [{ required: true, message: '必填', trigger: 'change' }],
        carrierCode: [{ required: true, message: '必填', trigger: 'change' }],
        currencyCode: [{ required: true, message: '必填', trigger: 'change' }],
        feeItemFees: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  computed: {
    infoParams() {
      const { shipInfo } = this.row
      const { id, regId, regType, settleEntityCode, carrierCode, currencyCode, feeItemFees } = shipInfo
      const carrier = this.findCarrier(carrierCode)
      const { cargoOwnerName: settleEntity } = this.cargoOwnerNameList.find(e => e.cargoOwnerCode === settleEntityCode) || []
      const regItemDetailsList = [{ feeItem: '关税', feeItemCode: 'FYPD009', feeItemFees: feeItemFees, id, regId, regType }]
      const obj = Object.assign({}, shipInfo, { regItemDetailsList }, {
        carrierCode, carrier, settleEntity, settleEntityCode, currencyCode, currency: this.findName(currencyCode, 'CURRENCY_TYPE'), feeSnippet: '关税', feeSnippetCode: 'FYPD009',
        feeType: '关税', feeTypeCode: 'FYPD009'
      })
      let regItemList = []
      regItemList.push(obj)
      regItemList = regItemList.map(item => { return { ...item, onePriceTag: 0 } })
      shipInfo.onePriceTag = 0
      const regVO = Object.assign({}, { regItemList }, shipInfo, { onePriceTag: 0 }, { createById: store.getters.userId, createByName: store.getters.name })
      const jsonContent = JSON.stringify(Object.assign({}, { shipInfo, regVO }))
      // console.log(jsonContent)
      const formDataParams = new FormData()
      const params = Object.assign({}, { file: this.file, jsonContent })
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))
      return formDataParams
    },
    title() {
      const type = this.$route.query.type
      return type === 'offer' ? '物流报价登记' : type === 'carriers' && this.row.shipInfo.feeSnippet === '关税' ? '清关承运商变更' : type === 'carriers' ? '物流费用变更' : type === 'info' ? '清关信息登记' : ''
    },
    operate() {
      const type = this.$route.query.type
      return type === 'offer' ? '提交' : type === 'carriers' ? '变更' : type === 'info' ? '保存' : ''
    },
    cancelTxt() {
      const type = this.$route.query.type
      return type === 'view' ? '返回' : '取消'
    },
    submitParams() {
      const { shipInfo, list } = this.row
      // console.log(list)
      const details = JSON.parse(sessionStorage.getItem('details')) || []
      const { shipInfoId, containerNo, expectContainerNo } = shipInfo
      const offerMethod = Object.assign({}, { details, shipInfoId, containerNo, expectContainerNo, shipNo: shipInfo.shipNo, offerNo: shipInfo.shipNo })
      const regItemList = list.map(item => {
        const feeDetail = deepClone(item.regItemDetailsList || [])
        const { cargoOwnerName: settleEntity } = this.cargoOwnerNameList.find(e => e.cargoOwnerCode === item.settleEntityCode) || []
        const { carrierCode, feeTypeCode, currencyCode, settleEntityCode } = item
        const carrier = this.findCarrier(carrierCode)
        // console.log(carrier)
        return {
          carrierCode, carrier, settleEntity, settleEntityCode, currencyCode, onePriceTag: item.onePriceTag,
          feeTypeCode: item.feeTypeCode, feeSnippet: item.feeSnippet, feeSnippetCode: item.feeSnippetCode,
          feeType: item.onePriceTag ? '一口价' : this.findName(feeTypeCode, 'FIN_COST_CLASS'),
          currency: this.findName(currencyCode, 'CURRENCY_TYPE'), regItemDetailsList: feeDetail.map(e => {
            delete e.code
            delete e.label
            return { ...e, feeItemSpecialFees: 0 }
          }).filter(e => e.feeItemFees)
        }
      })
      const regVO = Object.assign({}, { regItemList }, shipInfo, { createById: store.getters.userId, createByName: store.getters.name })
      delete regVO.id
      const jsonContent = JSON.stringify(Object.assign({}, { offerMethod, shipInfo, regVO }))
      const params = Object.assign({}, { file: this.file, jsonContent })
      const formDataParams = new FormData()
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))
      return formDataParams
    },
    carrierParams() {
      const { shipInfo, list } = this.row
      const { shipHarbor, importHarbor, invoiceNo } = shipInfo
      const cloneList = deepClone(list)
      let regItemDetailsList = []
      let obj = {}
      const { id, regId, regNo, regType, estimatePayableNo, regItemId } = shipInfo
      regItemDetailsList = cloneList.map((item, index) => {
        const { cargoOwnerName: settleEntity } = this.cargoOwnerNameList.find(e => e.cargoOwnerCode === item.settleEntityCode) || []
        const { carrierCode, feeTypeCode, currencyCode, settleEntityCode, onePriceTag } = item
        const carrier = this.findCarrier(carrierCode)
        const feeType = item.onePriceTag ? '一口价' : this.findName(feeTypeCode, 'FIN_COST_CLASS')
        const currency = this.findName(currencyCode, 'CURRENCY_TYPE')
        // 关税的承运商变更一口价：0
        index === 0 ? obj = Object.assign({}, { settleEntity, carrierCode, feeTypeCode, currencyCode, settleEntityCode, carrier, feeType, currency, onePriceTag: this.row.type === 'carriers' && this.row.shipInfo.feeSnippet === '关税' ? 0 : onePriceTag }) : ''
        // 过滤调费用是0的项目
        item.regItemDetailsList = item.regItemDetailsList.map(e => {
          delete e.regItemSpecialDetailsList
          delete e.ts
          return { ...e, id, regId, regNo, regType, estimatePayableNo, regItemId }
        })
        return { regItemDetailsList: item.regItemDetailsList.filter(e => e.feeItemFees) || [] }
      })
      const changeRegItemVO = Object.assign({}, regItemDetailsList[0], shipInfo, obj, { modifyByName: store.getters.name, modifyById: store.getters.userId })
      // console.log(changeRegItemVO)
      let jsonContent = {}
      if (this.row.shipInfo.feeSnippet === '关税') {
        jsonContent = Object.assign({}, { changeRegItemVO }, { id: regId, shipHarbor, importHarbor, invoiceNo })
      } else {
        jsonContent = Object.assign({}, { changeRegItemVO },)
      }
      const params = Object.assign({ file: this.file, jsonContent: JSON.stringify(jsonContent) })
      const formDataParams = new FormData()
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))
      return formDataParams
    }

  },
  watch: {

  },
  mounted() {
    this._getcargoownerList()
    this._getVendorListByVendorType()
    this.row.type = this.$route.query.type
    sessionStorage.setItem('type', JSON.stringify(this.row.type))
    this.row.labelArr = this.$route.query.labelArr && JSON.parse(this.$route.query.labelArr) || []
    this.row.shipInfo = this.$route.query.shipInfo && JSON.parse(this.$route.query.shipInfo) || {}
    this.row.shipInfo.logisticsChannel = String(this.row.shipInfo.logisticsChannel)
    this.row.list = this.$route.query.list && JSON.parse(this.$route.query.list) || []

    this.row.type === 'offer' ? this.row.list = this.row.list.map(item => { return { ...item, totalPrice: item.totalPrice || 0 } }) : ''
    // 费用合计
    if (this.row.type === 'view' && this.row.shipInfo.feeSnippet !== '关税') {
      this.getResultPrice(1)
    }
    this.$route.meta.title = this.row.type === 'offer' ? '物流报价登记' : this.row.type === 'carriers' ? '承运商变更' : this.row.type === 'view' ? '登记详情' : this.row.type === 'info' ? '清关信息登记' : ''
    this.setTitle()
    if (this.row.type === 'carriers' && this.row.shipInfo.feeSnippet === '关税') {
      console.log('下拉取字典')
    } else {
      this.getFeeList()
      this.getResultPrice()
    }
  },
  methods: {
    stopScroll(evt) {
      evt = evt || window.event
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault()
        evt.stopPropagation()
      } else {
        // IE
        evt.cancelBubble = true
        evt.returnValue = false
      }
      return false
    },
    findCarrier(carrierCode) {
      // console.log(carrierCode)
      const obj = this.newCarrierList.find(item => String(item.vendorCode) === String(carrierCode)) || []
      return obj ? obj.vendorName : ''
    },
    async _getVendorListByVendorType() {
      const { datas } = await getVendorListByVendorType({ vendorType: '6' })
      this.newCarrierList = datas
    },
    handleChange(val, flag) {
      if (Number(val) <= 0) {
        this.$nextTick(() => {
          this.$set(this.row.shipInfo, flag, undefined)
        })
      }
    },
    handleUploadBtnClick() {
      this.$refs.fileId.dispatchEvent(new MouseEvent('click'))
    },
    setTitle() {
      document.title = `${this.$route.meta.title} - ${defaultSettings.title}`
    },
    download(url) {
      window.open(url, '_blank')
    },
    async downloadByThoms(httpUrlString) {
      const res = await downloadByThoms({ httpUrlString })
      const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream;charset=utf-8' }))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      const arr = httpUrlString.split('/')[httpUrlString.split('/').length - 1].split('.')
      const fileName = arr[0]
      link.setAttribute('download', fileName + '.' + arr[1])
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
    },
    clearFile() {
      this.file = ''
      this.row.shipInfo.filePath = ''
      this.$refs.fileId.value = ''
    },
    findName(code, key) {
      const { label } = this._getAllCommodityDict(key).find(item => item.val === code) || []
      return label
    },
    getFile() {
      const files = this.$refs.fileId.files[0]
      this.file = files
    },
    async getFeeList() {
      const { datas } = await feeItemOnSelect()
      this.FeeList = datas
      this.row.list.map((item, index) => {
        const arr = item.feeSnippet.split(',')
        /*   const list = FeeList.filter(Fee =>
          arr.every(e => Fee.nextSelectedItems.find(ee => ee.label === e.trim()))
        )
        this.$set(item, 'carrierList', list)
        this.carrierList.push(list) */
        const list = this.FeeList.filter(Fee => {
          const flag = arr.map(e => Fee.nextSelectedItems.find(ee => ee.label === e.trim()))
          if (flag.every(item => item)) return true
        }
        )
        // console.log(list)
        this.$set(item, 'carrierList', list)
      })
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            if (this.row.type === 'offer') {
              if (this.row.list.find(item => !item.totalPrice)) {
                this.$message.warning('存在费用项目总金额为0的报价片段，请核对！')
                return
              }
              if (!this.row.shipInfo.logisticsChannel) {
                this.$message.warning('请选择渠道！')
                return
              }
              // console.log(this.submitParams)
              const { code, msg } = await reg(this.submitParams)
              if (code === 0) {
                this.$notify({
                  message: msg,
                  type: 'success'
                })
                this.$router.go(-1)
              }
            }
            if (this.row.type === 'carriers') {
              if (this.row.list.find(item => !item.totalPrice)) {
                this.$message.warning('存在费用项目总金额为0费用片段，请核对！')
                return
              }
              // console.log(this.carrierParams)
              const { code, msg } = await carrierChangeForRegItem(this.carrierParams)
              if (code === 0) {
                this.$notify({
                  message: msg,
                  type: 'success'
                })
                this.$router.go(-1)
              }
            }
            if (this.row.type === 'info') {
              // console.log(this.infoParams)
              const { code, msg } = await clearanceReg(this.infoParams)
              if (code === 0) {
                this.$notify({
                  message: msg,
                  type: 'success'
                })
                this.$router.go(-1)
              }
            }
          } finally {
            this.submitLoading = false
          }
        }
      })
    },
    getFeeitemList(val, item) {
      // 获取费用项目
      if (!item.onePriceTag) {
        const obj = this.FeeList.find(e => e.code === item.carrierCode).nextSelectedItems.find(ee => ee.label === item.feeSnippet).nextSelectedItems.find(E => E.code === item.feeTypeCode) || []
        obj.nextSelectedItems = obj.nextSelectedItems.map(item => { return { ...item, feeItem: item.label, feeItemCode: item.code, feeItemFees: 0 } })
        this.$set(item, 'regItemDetailsList', obj.nextSelectedItems)
      } else {
        this.$set(item, 'regItemDetailsList', [{ feeItem: '一口价', feeItemCode: 'FYXM999', feeItemFees: 0 }])
      }
    },
    getCostTypeList(val, item) {
      if (this.row.type === 'carriers' && this.row.shipInfo.feeSnippet === '关税') {
        this.getResultPrice(1)
      } else {
        this.$set(item, 'costTypeList', [])
        item.feeTypeCode ? this.$set(item, 'feeTypeCode', '') : ''
        this.$set(item, 'regItemDetailsList', [])
        // 重新计算汇总
        this.$set(item, 'totalPrice', 0)
        this.getResultPrice(1)
      }
    },

    visibleChange(val, item) {
      if (val) {
        let List = []
        if (!item.carrierCode) {
          this.$message.warning('请先选择承运商')
          return
        }
        const obj = this.FeeList.find(e => e.code === item.carrierCode)
        const arr = item.feeSnippet.split(',')
        if (obj) {
          arr.map(e => {
            const list = obj.nextSelectedItems.find(ee => ee.label === e)
            List.push(list)
          })
        }

        List = List.map(item => { return { ...[item.nextSelectedItems] } })
        let result = []
        List.map(item => { result.push(...Object.values(item)) })

        result = result.reduce(function(a, b) { return a.concat(b) })
        // 一口价费用类别默认为一口价
        if (item.onePriceTag) {
          this.$set(item, 'costTypeList', [{ label: '一口价', code: 'FYX999' }])
        } else {
          this.$set(item, 'costTypeList', result)
        }
      }
    },

    getResultPrice(flag) {
      if (flag && flag === 1) {
        this.cny = 0
        this.usd = 0
      }
      this.row.list.map(item => {
        if (item.currencyCode === 'CNY') {
          this.cny = FloatAdd(Number(this.cny), item.totalPrice)
        }
        if (item.currencyCode === 'USD') {
          this.usd = FloatAdd(Number(this.usd), item.totalPrice)
        }
      })
    },
    blurFee(val, item) {
      this.cny = 0
      this.usd = 0
      this.$set(item, 'totalPrice', 0)
      const value = val.target.value
      // 小数
      const flag = value && value.indexOf('.') !== -1 && value.split('.').length === 2 && value.split('.')[1].length > 2
      let sum = 0
      if (validAmount(value)) {
        // 遍历费用累加,汇总
        item.regItemDetailsList.map(item => {
          item.feeItemFees ? sum = FloatAdd(sum, Number(item.feeItemFees)) : ''
        })
        this.$set(item, 'totalPrice', sum)
        this.getResultPrice()
        return value
      }
      // 系统保留小数点后两位
      if (!validAmount(value) && flag) {
        item.regItemDetailsList.map(item => {
          item.feeItemFees ? sum = FloatAdd(sum, Number(Number(item.feeItemFees).toFixed(2))) : ''
        })
        this.$set(item, 'totalPrice', sum)
        this.getResultPrice()
        return Number(value).toFixed(2)
      }
      if (!validAmount(value)) {
        item.regItemDetailsList.map(item => {
          item.feeItemFees && item.feeItemFees >= 0 ? sum = FloatAdd(sum, Number(item.feeItemFees)) : ''
        })
        this.$set(item, 'totalPrice', sum)
        this.getResultPrice()
        return 0
      }
    },
    cancel() {
      this.$router.go(-1)
      // 登记回到未出费用，其他的是已出费用
    },
    downTemplate() { },
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    fileChange(file, fileList) {
      console.log(file, fileList)
      this.file = file.raw
    },
    uploadFile() { }
  }
}
</script>

<style scoped lang="scss">
.logistic {
  width: 100%;
  border: 1px solid #333;
}
.first {
  /deep/.el-input__inner {
    width: 120px !important;
  }
}
/deep/ .el-form-item__label {
  padding-right: 3px;
  font-weight: normal !important;
  line-height: 36px;
}
.two {
  width: 100%;
  border: 1px solid #333;
}
.three {
  width: 100%;
  border: 1px solid #333;
}
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}

.invoice {
  /deep/.el-input__inner {
    width: 180px !important;
  }
}
</style>
